import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx"

import Seo from "../components/seo";
import template from "../utils/template";

export const pageQuery = graphql`
  query PageQuery($id: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        title
        contactEmail
      }
    }
    mdx(id: { eq: $id }) {
      id
      body
      excerpt(pruneLength: 140)
      frontmatter {
        title
        alternate
      }
    }
  }
`;
const Page = ({ data }) => {
  const { mdx } = data;
  const { frontmatter, body, excerpt } = mdx;

  return (
    <>
      <Seo
        title={frontmatter.title}
        description={excerpt}
        alternate={frontmatter.alternate}
      />
      <div className="flex flex-row">
        <article className="blog-post md:w-4/6">
          <header className="my-6">
            <h1 className="text-4xl md:text-6xl font-bold">
              {frontmatter.title}
            </h1>
          </header>
          <div className="my-8 leading-loose text-xl">
            <MDXRenderer>
              {template(body, {
                siteUrl: data.site.siteMetadata.siteUrl,
                siteName: data.site.siteMetadata.title,
                siteContactEmail: data.site.siteMetadata.contactEmail,
              })}
            </MDXRenderer>
          </div>
        </article>
      </div>
    </>
  );
};

export default Page;
